* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.main-container {
  background: rgb(80, 80, 80);
}
button {
  border-radius: 8px;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  color: white;
}
.user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30px;
  margin: auto 30px;
}
.logo-box {
  height: 40px;
  width: 100px;
  margin: auto 30px;
}
.logo-box img {
  height: 100%;
  width: 100%;
}
.user-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30x;
  width: 80px;
}
.user-img img {
  /* filter: invert(); */
  height: 20px;
  width: 20px;
}

.main-body {
  height: auto;
  width: 100%;
}
.broad-cast {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c0392b;
}
.broad-cast h1 {
  color: white;
  font-size: 25px;
}
.video-streams-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 290px;
  width: 100%;
  background: white;
}
.video-streams-box div span h4 {
  height: 20px;
  text-align: center;
  color: #c0392b;
}
.inputBox {
  width: 100%;
}
.inputBox div {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 41%;
}
.instruction-para {
  text-align: justify;
}
.submit-box span {
  padding: 10px;
}
.submit-box h3 {
  color: white;
}
.submit-box button {
  border-radius: 10px;
}
.submit-box button:hover {
  background-color: #ca5a4d;
  border-radius: 10px;
}
.inputBox div input {
  width: 100%;
  margin: 10px;
  padding: 6px;
}
.inputBox div button {
  width: 20%;
  margin: 10px;
  padding: 6px;
  border-radius: 8px;
  background-color: white;
}
.inputBox div input::placeholder {
  padding: 5px;
  font-size: 14px;
}
.controlBox {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95px;
  flex-direction: column;
}
.controlBox div {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  flex-direction: column;
}
.controlBox div span {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controlBox div span button {
  width: 50%;
  margin: 10px;
  padding: 6px;
}
.go-live {
  color: white;
  background: #c0392b;
}
.go-live:hover {
  background-color: #ca5a4d;
  border-radius: 10px;
}
.stop-button {
  background: #000;
  color: white;
}
.stop-button:hover {
  background-color: #ca5a4d;
  border-radius: 10px;
}
.decleration-box {
  padding: 10px;
  height: 75px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  color: white;
  flex-direction: column;
}
.decleration-box .instruction {
  width: 100%;
  color: #c0392b;
}
.decleration-box .instruction-para {
  width: 100%;
}
.video-box {
  border: 2px solid #c0392b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  border-radius: 18px;
  position: relative;
}
.frame-video {
  border-radius: 10px;
  height: 240px;
  width: 410px;
}

nav {
  display: flex;
}

.live-lable {
  color: #c0392b;
}
.offline-lable {
  color: white;
}
.liveBar {
  position: absolute;
}
.liveBar img {
  position: absolute;
}
.dumy-text {
  height: 85% !important;
  width: 90%;
  top: 10px;
  bottom: 0px;
  position: absolute;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
iframe {
  padding: 10px;
}
hr {
  border: 1px solid #c0392b;
}
.instruction-para p {
  font-size: 12px;
}
@media only screen and (max-width: 600px) {
  .main-container {
    height: 750px;
  }
  .user-name h3 {
    font-size: 9px;
  }
  .frame-video {
    width: 300px;
  }
  .video-box {
    width: 310px;
  }
  .submit-box {
    height: 55px !important;
    width: 85% !important;
  }
  .submit-box h3 {
    font-size: 12px;
  }
  .inputBox-outer {
    width: 85% !important;
  }
  .decleration-box {
    width: 85% !important;
  }
  .controlBox {
    margin-top: 50px;
  }
  .controlBox div {
    width: 100% !important;
  }
  .controlBox span {
    width: 100% !important;
  }
}
