* {
  font-family: "Poppins", sans-serif !important;
}

.hello {
  position: absolute;
  top: 2px;
  right: 10px;
}
// input{
//   font-size: 8px !important;
// }

.menu-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30px;
  width: 10px;

  span {
    background-color: #000000b8;
    height: 3px;
    width: 3px;
    margin: 1px 0;
    border-radius: 50%;
  }
}

.alert-message {
  position: absolute;
  top: 7rem;
  font-size: 10px !important;
}

.anchor {
  color: #000000;
}

.signin-body {
  min-height: 100vh;
  height: auto;
  margin-top: 3rem;

  .gamecast-back {
    cursor: pointer;
    // position: absolute;
    top: 4.6rem;
    left: 0;
    z-index: 1;
    font-size: 35px;
  }

  .kababmenu {
    position: absolute;
    z-index: 1;
    top: 0rem;
    right: 2rem;
  }

  .kababmenu-venue {
    position: absolute;
    z-index: 1;
    top: 0.35rem;
    right: 1rem;
  }

  .main-box {
    background-color: white;
    position: relative;

    .venue-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      width: 100%;

      .venue-name {
        font-size: 1.15rem;
        font-weight: 600;
      }

      .venue {
        margin: 10px 0;
        border: 1px solid;
        width: 100%;
        padding: 20px !important;
        height: 1.4375em;
        font-size: 1.15rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
    }

    .customer-detail-box {
      border: 1px solid;
      padding: 0.4rem 1rem;

      .details {
        color: #919191;
        padding: 0.1rem 0;
      }
    }

    .pagination-list {
      display: flex;
      justify-content: center;
      list-style: none;
      margin-top: 6px;

      .page-items {
        padding: 4px 10px;
        font-size: 15px;
        cursor: pointer;
      }

      .page-links {
        color: black !important;
      }

      .page-links:hover {
        border: none !important;
        text-decoration: none;
      }

      .active-item {
        border-bottom: 3px solid #e21c21;
      }
    }

    #subscription {
      width: 100%;
      margin: 16px 0px;
      border-radius: 4px;
      outline: none;
      border: 1px solid #b7b7b7;
      background: transparent;
      color: #605e5e;
      position: relative;
      padding: 7px 10px;
      font-size: 1rem;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      z-index: 3;
    }

    .timeZoneBox {
      position: relative;

      i {
        position: absolute;
        z-index: 1;
      }
    }

    #timeZone {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 10px;
      border-radius: 4px;
      outline: none;
      border: 1px solid #b7b7b7;
      background: transparent;
      color: #605e5e;
      position: relative;
      padding: 8px 10px;
      font-size: 1rem;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      z-index: 3;
    }

    .heading-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .heading {
        color: black;
        font-weight: 600;
      }
    }

    .gamecast-logo {
      width: 130px;
      cursor: pointer;
    }

    .sign-in-button {
      background-color: black !important;
      text-transform: none !important;
      padding: 20px !important;
      height: 1.4375em;
      font-size: 1.15rem;
      font-weight: 600;
    }
    .button {
      background-color: black !important;
      text-transform: none !important;
      padding: 20px !important;
      width: 100%;
      height: 1.4375em;
      font-size: 1.15rem;
      font-weight: 600;
    }
    .template-button {
      background-color: black !important;
      text-transform: none !important;
      padding: 20px !important;
      height: 1.4375em;
      font-size: 1.15rem;
      font-weight: 400;
    }
    .checkbox-container {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      input {
        height: 1rem;
        width: 1rem;
        // font-size: 8px;
      }

      label {
        margin-left: 0.5rem;
        margin-bottom: 0 !important;
      }
    }

    .searchBox {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      input {
        width: 100%;
        padding: 6px;
      }

      .searchIcon {
        position: absolute;
        right: 1rem;
        font-size: 14px;
        color: #a3a2a2;
      }
    }

    .menuList {
      z-index: 11 !important;
    }

    .tableView {
      width: 21rem;
      min-height: 22rem;
      height: auto;
      padding: 0 2px;
      overflow: auto;
    }

    .rtmp-preview-box {
      border: 2px solid;
      border-radius: 24px;
      height: 15rem;
      margin-top: 10px;
      margin-bottom: 20px;

      iframe {
        border-radius: 20px;
        padding: 0px !important;
      }
    }
  }
}

#video_info {
  top: 50% !important;
}

.timeZoneBox {
  position: relative;

  .select-icon {
    position: absolute;
    top: 30px;
    right: 15px;
    z-index: 1;
  }
}

.activ-inactive {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #000000;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #000000;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.dailog-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .caution-img-box {
    padding: 5px;

    .caution-image {
      font-size: 3rem;
      color: #e21c21;
    }
  }

  .dailog-content-box {
    display: flex;
    flex-direction: column;
    justify-content: start;

    h1 {
      margin-top: 0 !important;
      padding: 0;
      font-size: 2rem !important;
    }

    p {
      color: grey;
      padding: 0 !important;
      font-size: 16px;
    }
  }
}

.dailog-button {
  background-color: black !important;
}

.margin-negative {
  margin-top: -8px !important;
}

@media screen and (min-width: 750px) {
  .alert-message {
    position: absolute;
    top: 9rem;
    font-size: 10px !important;
  }

  .signin-body {
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafbfd;
    margin-top: 0;

    .gamecast-back {
      top: 6.8rem;
      left: 2rem;
    }

    .kababmenu {
      top: 2rem;
      right: 2rem;
    }

    .kababmenu-venue {
      top: 0.35rem;
      right: 1rem;
    }

    .main-box {
      margin-top: 2rem;
      padding: 2rem;
      min-height: 37rem;
      height: auto;
      width: 25rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      .tableView {
        width: 21rem;
        min-height: 22rem;
        height: auto;
        overflow: auto;
        padding: 0 2px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .signin-body {
    .main-box {
      .heading {
        font-size: 1.5rem;
      }
    }
  }
}

//error
.error-box {
  padding: 20px 0px;
  margin-top: 20px;

  .error-message {
    color: #e21c21;
  }

  a {
    color: #e21c21;
  }
}

///customer section
.broadcasts-container {
  min-height: 16rem;
  height: auto;
}

.saved-button {
  background-color: #e21c21 !important;
}

#social {
  width: 100%;
  margin: 16px 0px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #b7b7b7;
  background: transparent;
  color: #605e5e;
  position: relative;
  padding: 7px 10px;
  font-size: 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  z-index: 3;
}

.customer-detail {
  border: 1px solid;
  padding: 13px;

  .detail {
    font-weight: 500;
    font-size: 14px;
  }
}

.location-icon {
  color: #e21c21;
  margin: 0 10px;
}

.venue-customer {
  margin: 8px 0;
  border: 1px solid;
  width: 100%;
  padding: 20px !important;
  height: 1.4375em;
  font-size: 1.15rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #000000;
  color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}

.go-live-box {
  padding: 15px;
  border: 2px solid;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //background: linear-gradient(#808184, #404142);
  color: black !important;
  border-radius: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .manual-heading {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    .live-dot {
      position: absolute;
      right: -30px;
      height: 15px;
      width: 15px;
      margin: 0px 10px;
      border-radius: 50%;
      background-color: #e21c21;
      animation: blink-animation 1s steps(5, start) infinite;
      -webkit-animation: blink-animation 1s steps(5, start) infinite;
      transition: all 0.8ms ease-in-out;
    }
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .preview-heading {
    margin-top: 0px !important;
    color: black;
  }

  .live-heading {
    color: #e21c21;
  }

  .golive-button {
    background-color: #e21c21;
    border: 1px solid #e21c21;
    text-transform: none;
    font-size: 0.7rem;
    width: 70px !important;
    padding: 6px;
  }

  .golive-button-active {
    background-color: #000000;
    border: 1px solid #e21c21;
    text-transform: none;
    font-size: 0.7rem;
    width: 70px !important;
    padding: 6px;
  }

  .golive-button-active:hover {
    background-color: #000000;
    border: 1px solid #e21c21;
    font-size: 0.7rem;
    width: 70px !important;
    padding: 6px;
  }

  .golive-button:hover {
    border: 1px solid #e21c21;
    background-color: transparent;
    color: black;
    font-size: 0.7rem;
    width: 70px !important;
    padding: 6px;
  }
}

.broadcast-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(#808184, #404142);
  color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  margin-bottom: 10px;

  h1 {
    margin-top: 0 !important;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
}

.broadcastOptions {
  .menu-icon {
    span {
      background-color: white;
    }
  }
}

.date-box {
  input {
    width: 100%;
    padding: 8.5px 16px;
    border-radius: 4px;
    border: 1px solid #bdbcbc;
    margin: 10px 0px;
    font-weight: 500 !important;
  }

  input:focus {
    border: 1px solid #e21c21;
  }
}

.venue-box-dashboard {
  min-height: 14rem;
  height: auto;

  .venue-customer-dashboard {
    margin: 12px 0;
    border: 1px solid;
    width: 100%;
    padding: 20px !important;
    height: 1.4375rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #000000;
    color: white;

    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;

    .venue-name {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.edit-color {
  background-color: #e21c21 !important;
  text-transform: none !important;
  padding: 20px !important;
  height: 1.4375em;
  font-size: 1.15rem;
  font-weight: 600 !important;
}

//footer
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}

.timeInput {
  width: 100%;
  margin: 10px 0;

  input {
    color: #000000 !important;
    font-size: 16px;
    padding: 20px 8px;
  }

  .rc-time-picker-clear {
    display: none;
    pointer-events: none !important;
    cursor: default !important;
  }

  .rc-time-picker-panel-select {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  display: none !important;
}

// .timeInput{
//   input:focus-visible {
//     outline-color: #e21c21 !important ;
//     border: #e21c21;
//   }
// }
//date Picker
.datePicker {
  width: 100%;
  padding: 8px 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 6px;
}

//time
.time-box {
  position: relative;
  padding: 1px;

  label {
    position: absolute;
    left: 11px;
    font-size: 12px;
    color: #5e5e5e;
    background-color: white;
    width: 9rem;
    text-align: center;
  }
}

.time-date-box {
  position: relative;

  input {
    display: none;
  }

  label {
    position: absolute;
    z-index: 111;
    left: 11px;
    font-size: 12px;
    color: #5e5e5e;
    background-color: white;
    width: 7rem;
    text-align: center;
  }
}